<template>
    <div class='container mx-auto'>
        <p>
            <prismic-image :field="img" class="w-full rounded-lg"/>
        </p>
        <p>
            <prismic-rich-text v-if="caption != ''" :field="caption" class="block text-sm italic text-center"/>
        </p>
    </div>
</template>

<script>
export default {
    props: ['slice'],
    name: 'image-caption-slice',
    data: function() {
        return {
        img: '',
        caption: '',
        size: '',
        }
    },
    created () {
        this.img = this.slice.primary.image
        this.caption = this.slice.primary.caption
        this.size = this.slice.slice_label
    }
    
}
</script>